















































































import { Component, Vue } from 'vue-property-decorator';
import CitySection from '@/client/components/core/CitySection.vue';

@Component({
  components: {
    CitySection,
  },
})
export default class McKinney extends Vue {
  // eslint-disable-next-line
  mounted() {
    console.log('here');
  }
}
