export default async function api(method: any, endpoint: string, params?: any) {
  let config = {};

  if (params) {
    config = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params || ''),
    };
  } else {
    config = {
      method,
      headers: {
        'Content-Type': 'application/json',
      },
    };
  }

  return fetch(endpoint, config)
    .then((response: any) => response.json())
    .catch((err) => {
      console.log(err);
      throw err;
    });
}
