









































































import { Component, Vue } from 'vue-property-decorator';
import CitySection from '@/client/components/core/CitySection.vue';

@Component({
  components: {
    CitySection,
  },
})
export default class FortWorth extends Vue {}
