




























































































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import api from '../util/api';
// @ts-ignore
import { ProfileCard, InfoAreas, Parallax, FullBgCard } from '@/client/components/_theme';
import Contact from '@/client/components/core/Contact.vue';

@Component({
  components: {
    ProfileCard,
    InfoAreas,
    Parallax,
    FullBgCard,
    Contact,
  },
})
export default class MainNavbar extends Vue {
  image: string = '/solar-panel-demo.jpeg';
  cardAbout: any = {
    image1: '/solar-panel-head.jpg',
    image2: '/hvac-5.jpg',
  }

  // eslint-disable-next-line
  sectionBackground(image: string) {
    return {
      backgroundImage: `url(${image})`,
    };
  }
}
