






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import Contact from '@/client/components/core/Contact.vue';

@Component({
  components: {
    Contact,
  },
})
export default class Cities extends Vue {}
