










































































































































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
// @ts-ignore
import { Parallax, InfoAreas, ProfileCard, FullBgCard } from '@/client/components/_theme';
import Contact from '@/client/components/core/Contact.vue';
import CitySection from '@/client/components/core/CitySection.vue';
import path from 'path';
import api from '../util/api';

@Component({
  components: {
    Parallax,
    InfoAreas,
    ProfileCard,
    FullBgCard,
    Contact,
    CitySection,
  }
})
export default class Landing extends Vue {
  image: string = '/solar-panel-demo.jpeg';
  cardProfile3: any = {
    cardProfile1: '/anthony-profile.jpg',
    cardProfile2: '/elliott-profile.jpg',
  };

  cardAbout: any = {
    image1: '/hvac-5.jpg',
    image2: '/hvac-6.jpg', 
  };
}
